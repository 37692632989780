<template>
  <div
    id="left_side_navbar"
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  height="40"
                  :src="appLogoIcon"
                  alt="logo"
                  class="brand-logo-dark"
                />
              </span>
              <b-img
                height="40"
                :src="appLogoText"
                alt="logo"
                class="brand-text"
              />
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle d-none">
            <b-link
              v-b-tooltip.hover.right="'Toggle side bar'"
              class="nav-link modern-nav-toggle"
            >
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <template v-if="doneNavItems">
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area mt-3"
        tagname="ul"
        @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
      >
        <vertical-nav-menu-items
          :items="updatedNavMenuItems"
          :is-expanded="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)"
          class="navigation navigation-main"
        />
      </vue-perfect-scrollbar>
    </template>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, VBTooltip } from 'bootstrap-vue'
import {
  provide, computed, ref, watch,
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { apiToastWarning } from '@core/utils/toast'
import connectionIcon from '@/assets/images/icons/connectionsIcon.svg'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { parseResource } from '@/@core/utils/utils'
import { NAV_ITEM_ID } from '@/constants'
import { GET_COMMON_AUTH } from '@/store/modules/common.module'
import store from '@/store'
import { UPDATE_DIRECT_BRANDS } from '@/store/modules/shop.module'
import _ from 'lodash'
import { Settings } from '@/Settings'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

const { BRAND_ACCESS, ROLES } = constants

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props, { root }) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
      NAV_MENU_ITEM,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const {
      appName,
      appLogoImage,
      appLogoIcon,
      appLogoText,
    } = $themeConfig.app

    const doneNavItems = ref(false)

    const updatedNavMenuItems = ref([])

    const userRole = computed(() => root.$store.getters.entityType)
    const isBrand = computed(() => root.$store.getters.isBrand)
    const isRetailer = computed(() => root.$store.getters.isRetailer)
    const isGuest = (userRole.value === ROLES.GUEST)
    const profile = computed(() => root.$store.getters.profile)
    let isBrandsAccessible = true
    setTimeout(() => {
      if (isRetailer.value) {
        isBrandsAccessible = profile.value?.access?.includes(BRAND_ACCESS.MARKETPLACE)
      }
    }, 600)
    const navMenuItems = [{
      header: 'Welcome to Kingpin',
    },
      NAV_MENU_ITEM.DASHBOARD(isGuest),
      NAV_MENU_ITEM.PROFILE(isGuest),
    ]
    const buildRealNavMenu = async () => {
      const realNavMenuItems = ref([])
      realNavMenuItems.value = JSON.parse(JSON.stringify(navMenuItems)) || []
      // for retailer
      realNavMenuItems.value.push()
      if (userRole.value === ROLES.RETAILER || isGuest) {
        // wishlist
        realNavMenuItems.value.push(NAV_MENU_ITEM.WISHLIST(isGuest))
        realNavMenuItems.value.push(NAV_MENU_ITEM.CHECKOUT(isGuest))
        try {
          let directBrands = []
          const directBrandsMenu = []
          if (isRetailer.value) {
          await store.dispatch(GET_COMMON_AUTH).then(res => {
            directBrands = res?.data?.data?.DIRECT_BRANDS || []
          })
          .catch(() => {
            directBrands = []
          })
          store.commit(UPDATE_DIRECT_BRANDS, directBrands)
          directBrands.forEach((brand, index) => {
              directBrandsMenu.push({
                tagId: index === 0 ? 'intro_explore' : '',
                title: brand.name,
                img: brand.logo,
                route: {
                  header: brand.name,
                  name: 'brand/detail',
                  params: { brandId: brand._id },
                },
                ignoreActive: true,
                resource: 'Retailer',
              })
            })
          }

          realNavMenuItems.value = [
            ...JSON.parse(JSON.stringify(navMenuItems)),
            ...[
              {
                tagId: 'intro_explore',
                title: 'Explore',
                icon: 'ShoppingBagIcon',
                navItemId: NAV_ITEM_ID.EXPLORE,
                children: [
                ...directBrandsMenu,
                ...[
                  {
                    title: 'Premium Brands',
                    route: 'premiumbrands',
                    resource: 'Retailer',
                    disabled: !isBrandsAccessible,
                    icon: !isBrandsAccessible ? 'SlashIcon' : '',
                  },
                  {
                    title: 'All Brands',
                    route: 'explore',
                    resource: parseResource('Retailer'),
                    disabled: !isBrandsAccessible,
                    icon: !isBrandsAccessible ? 'SlashIcon' : '',
                  },
                ]],
              },
            ],
            ...realNavMenuItems.value.splice(navMenuItems.length),
          ]
          doneNavItems.value = true
        } catch (err) {
          apiToastWarning(err)
        }
      }
      // only navigation for brands
      else {
        realNavMenuItems.value.push(NAV_MENU_ITEM.COLLECTION)
        doneNavItems.value = true
      }
      realNavMenuItems.value.push(NAV_MENU_ITEM.CONNECTIONS(root.$store.getters.entityType === ROLES.BRAND ? 'My Retailers' : 'My Brands', connectionIcon, isGuest))
      if (isBrand) {
        realNavMenuItems.value.push(NAV_MENU_ITEM.SELECTION(isGuest))
        realNavMenuItems.value.push(NAV_MENU_ITEM.SALES_ANALAYTICS(isGuest))
      }
      // for all
      realNavMenuItems.value.push(NAV_MENU_ITEM.ORDERS(isGuest))
      realNavMenuItems.value.push(NAV_MENU_ITEM.SHIPMENTS(isGuest))
      realNavMenuItems.value.push(NAV_MENU_ITEM.DOWNLOADS(isGuest))
      if (isBrand) {
        if (profile.value?.isAnalyticsEnabled) {
          realNavMenuItems.value.push(NAV_MENU_ITEM.ANALYTICS)
        }
        if (profile.value?.isLeadAIEnabled) {
          realNavMenuItems.value.push(NAV_MENU_ITEM.LEAD_AI)
        }
      }
      realNavMenuItems.value.push({
        tagId: 'intro_help',
        title: 'Help',
        icon: 'HelpCircleIcon',
        href: Settings.getConfig('VUE_APP_HELP_URL'),
        resource: 'Help',
        action: 'read',
        navItemId: NAV_ITEM_ID.HELP,
      })
      realNavMenuItems.value.push({
        tagId: 'intro_knowledge_base',
        title: 'Knowledge Center',
        icon: 'BookOpenIcon',
        route: 'knowledge-center',
        resource: 'Help',
        action: 'read',
        navItemId: NAV_ITEM_ID.KNOWLEDGE_CENTER,
      })
      realNavMenuItems.value.push({
        tagId: 'intro_kingpin_academy',
        title: 'Kingpin Academy',
        icon: 'BookIcon',
        route: 'kingpin-academy',
        resource: 'Help',
        action: 'read',
        navItemId: NAV_ITEM_ID.KINGPIN_ACADEMY,
      })
      return realNavMenuItems.value
    }

    const loadNavMenues = async () => {
      updatedNavMenuItems.value = await buildRealNavMenu()
    }

    const updateNavMenuItems = async () => {
      const updatedMenues = await buildRealNavMenu()
      if (!_.isEqual(updatedMenues, updatedNavMenuItems.value)) {
        updatedNavMenuItems.value = updatedMenues
      }
    }

    loadNavMenues()
    // watch until profile is updated
    watch(profile, () => {
      updateNavMenuItems()
    })

    return {
      updatedNavMenuItems,
      doneNavItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoIcon,
      appLogoText,
    }
  },
}
</script>

<style lang="scss">
@import "src/@core/scss/base/core/menu/menu-types/vertical-menu.scss";
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';

.main-menu.menu-light .navigation>li.active>a {
  background: $navbar-active-bg-color !important;
  border-radius: 5px;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  color: $body-color;
  box-shadow: unset;
}
</style>
